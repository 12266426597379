import * as React from 'react';
import { gql } from '@apollo/client';
import { eachLimit } from 'async';
import useLazyVetspireQuery from 'hooks/useLazyVetspireQuery';
import useVetspireMutation from 'hooks/useVetspireMutation';

type UnsignedEncountersVariables = {
    providerId: string;
    startBefore?: Date;
    startAfter?: Date;
};

type UnsignedEncountersResult = {
    encounters: ReadonlyArray<{
        id: string;
    }>;
};

const unsignedEncountersQuery = gql`
    query encounters(
        $providerId: ID!
        $startBefore: NativeDateTime
        $startAfter: NativeDateTime
    ) {
        encounters(
            signed: false
            providerId: $providerId
            startBefore: $startBefore
            startAfter: $startAfter
        ) {
            id
        }
    }
`;

type SignEncounterVariables = {
    id: string;
};

type SignEncounterResult = {
    signEncounter: {
        id: string;
        signed: boolean;
    };
};

const signEncounterMutation = gql`
    mutation signEncounter($id: ID!) {
        signEncounter(id: $id) {
            id
            signed
        }
    }
`;

type BulkSignParams = {
    start?: Date;
    end?: Date;
    providerId: string;
};

export default function useBulkSignEncounters() {
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [runLoadEncounters] = useLazyVetspireQuery<
        UnsignedEncountersResult,
        UnsignedEncountersVariables
    >(unsignedEncountersQuery);
    const [runSignEncounter] = useVetspireMutation<
        SignEncounterResult,
        SignEncounterVariables
    >(signEncounterMutation);

    const bulkSignEncounters = React.useCallback(
        async ({ start, end, providerId }: BulkSignParams) => {
            let success = true;
            setError(null);
            setLoading(true);
            const { data } = await runLoadEncounters({
                variables: {
                    startBefore: end,
                    startAfter: start,
                    providerId,
                },
            });

            await eachLimit(
                [...(data?.encounters ?? [])],
                10,
                async (encounter) => {
                    const { data: encounterData } = await runSignEncounter({
                        variables: {
                            id: encounter.id,
                        },
                    });

                    if (!encounterData?.signEncounter?.signed) {
                        setError('Not all encounters could be signed');
                        success = false;
                    }
                },
            );

            setLoading(false);
            return success;
        },
        [runLoadEncounters, runSignEncounter],
    );

    return {
        bulkSignEncounters,
        loading,
        error,
    };
}
